<template>
    <v-container fluid class="pt-0 mt-0">
        <HeaderAzul :titulo="conteudo.disciplina" :subtitulo="conteudo.curso" />
        <v-row class="justify-center mt-2">
            <v-col cols="12" class="mb-0 pb-0">
                <v-card elevation="0" style="background-color: unset;">
                    <v-card-title style="font-size: 25px;" class="pb-3">
                        {{conteudo.conteudo}}
                        <v-spacer/>
                        <v-btn @click="dialogExibir({name : 'livroDialog', id_curso : parseInt(conteudo.id_curso), id_conteudo : parseInt(id_conteudo)})" icon><v-icon>mdi-book-open-page-variant</v-icon></v-btn>
                    </v-card-title>
                </v-card>
            </v-col>
            <template v-for="(cf, i) in conteudo.frases">
                <v-col v-if="cf.sei == 'f'" cols="12" md="6" lg="4" xl="3" :key="i">
                    <v-card>
                        <v-card-text class="pt-3 pb-0"><small>{{cf.level}}</small></v-card-text>
                        <v-card-text class="pt-3 my-0" v-html="`${i+1}. ${cf.frase}`" style="white-space: pre-line; color: #000;"/>
                        <v-divider/>
                        <v-card-text v-show="cf.resposta_exibir" v-html="cf.resposta" style="white-space: pre-line;"></v-card-text>
                        <v-divider v-if="cf.resposta_exibir"/>
                        <v-card-actions>
                            <v-btn v-if="cf.sei == 'f'" text @click="cf.sei = 't';sei(cf.id_frase)"><v-icon left>mdi-check</v-icon> Eu sei</v-btn>
                            <v-spacer/>
                            <v-btn text @click="cf.sei = cf.resposta_exibir && cf.sei == 't' ? 't' : 'f';cf.resposta_exibir = !cf.resposta_exibir;naoSei(cf.id_frase, cf.resposta_exibir)"><v-icon left>mdi-eye</v-icon> {{!cf.resposta_exibir ? 'Traduzir' : 'Ocultar'}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </template>
            <template v-for="(cf, i) in conteudo.frases">
                <v-col v-if="cf.sei == 't'" cols="12" md="6" lg="4" xl="3" :key="'s_'+i">
                    <v-card color="green" dark>
                        <v-card-text class="pt-3 pb-0"><small>{{cf.level}}</small></v-card-text>
                        <v-card-text class="pt-3 my-0" v-html="`${i+1}. ${cf.frase}`" style="white-space: pre-line; color: #FFF;"/>
                        <v-divider style="border-color: #FFFFFF33"/>
                        <v-card-actions>
                            <v-btn v-if="cf.sei == 'f'" text @click="cf.sei = 't';sei(cf.id_frase)"><v-icon left>mdi-check</v-icon> Eu sei</v-btn>
                            <v-spacer/>
                            <v-btn text @click="cf.sei = cf.resposta_exibir && cf.sei == 't' ? 't' : 'f';cf.resposta_exibir = !cf.resposta_exibir;naoSei(cf.id_frase, cf.resposta_exibir)"><v-icon left>mdi-eye</v-icon> {{!cf.resposta_exibir ? 'Traduzir' : 'Ocultar'}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </template>
            <v-col v-if="id_conteudo_revisao" cols="12" class="text-center">
                <v-btn color="primary" @click="revisaoConcluir"><v-icon left>mdi-check-decagram</v-icon>Concluir revisão e voltar para o início</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import axios from "axios"
import {mapState, mapMutations} from "vuex"
import HeaderAzul from "@/Views/Estrutura/HeaderAzul"

export default {
    name: "CursoMaterialConteudo",
    props : ['id_turma', 'id_conteudo', 'id_conteudo_revisao'],
    components: {HeaderAzul},
    computed : {
        ...mapState(['apiUrl', 'baseUrl'])
    },
    data: () => ({
        conteudo : {frases : []},
    }),
    methods : {
        ...mapMutations(['dialogExibir']),
        getCurso() {
            return axios.post(`${this.baseUrl}aula/revisao_conteudo_app`, {id_conteudo : this.id_conteudo, id_turma : this.id_turma}).then( (res) => {
                let dados = res.data
                /* eslint-disable no-console */
                dados.frases = dados.frases.map((v) => {return {...v, resposta_exibir : false}})
                this.conteudo = dados
            });
        },
        init() {
            this.getCurso()
        },
        async sei(id_frase) {
            await axios.post(`${this.baseUrl}aula/conteudo_frase_sei`, {id_turma : this.conteudo.id_turma, id_disciplina : this.conteudo.id_disciplina, id_curso : this.conteudo.id_curso, id_conteudo : this.id_conteudo, id_frase, id_level : this.conteudo.id_level})
        },
        async naoSei(id_frase, exibir) {
            if (exibir) {
                await axios.post(`${this.baseUrl}aula/conteudo_frase_nao_sei`, {id_turma : this.conteudo.id_turma, id_disciplina : this.conteudo.id_disciplina , id_curso : this.conteudo.id_curso, id_conteudo : this.id_conteudo, id_frase, id_level : this.conteudo.id_level})
            }
        },
        async revisaoConcluir() {
            await axios.post(`${this.apiUrl}aluno/conteudo/revisar/concluir`, {id_conteudo_revisao : parseInt(this.id_conteudo_revisao)}).then( (res) => {
                this.revisar = res.data
            })
            this.$router.push('/')
        }
    },
    activated() {
        this.init()
    },
}
</script>

<style scoped>

</style>